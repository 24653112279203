.profile-profile {
  display: flex;
  position: relative;
  flex-direction: column;
}

.profile-image {
  object-fit: cover;
  padding-bottom: var(--dl-space-space-halfunit);
}

.profile-title {
  color: inherit;
}

.profile-name {
  color: inherit;
}

.profile-bio {
  color: inherit;
  margin-top: var(--dl-space-space-doubleunit);
}

.profile-interests {
  color: inherit;
  font-style: italic;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-unit);
}

.profileroot-class-name1 {
  width: 100%;
}

.profileroot-class-name17 {
  width: 60%;
  align-self: center;
}

.profileroot-class-name18 {
  width: 60%;
  align-self: center;
}

.profileroot-class-name19 {
  width: 60%;
  align-self: center;
}

.profileroot-class-name20 {
  width: 60%;
  align-self: center;
}
