.archive-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
}

.archive-bg-container {
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  flex-direction: row;
}

.archive-image1 {
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}

.archive-image2 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}

.archive-image3 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}

.archive-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}

.archive-body-container {
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
}

.archive-row1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.archive-left-container {
  top: 0px;
  width: 65%;
  height: 100%;
  display: flex;
  overflow: scroll;
  position: fixed;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: 85px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.archive-title-container {
  display: flex;
  justify-content: space-between;
}

.archive-text100 {
  color: var(--dl-color-feelers-dark);
  width: 8%;
}

.archive-text101 {
  color: var(--dl-color-feelers-dark);
  width: 40%;
}

.archive-text102 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
}

.archive-text103 {
  color: var(--dl-color-feelers-dark);
  width: 10%;
}

.archive-right-container {
  top: 0px;
  right: 0px;
  width: 35%;
  height: 100%;
  display: flex;
  overflow: scroll;
  position: fixed;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.archive-text104 {
  color: var(--dl-color-feelers-dark);
  width: 65%;
  z-index: 4;
}

.archive-row2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.archive-text105 {
  display: inline-block;
}

.archive-text106 {
  display: inline-block;
}

.archive-text107 {
  display: inline-block;
}

.archive-text108 {
  display: inline-block;
}

.archive-text109 {
  display: inline-block;
}

.archive-text110 {
  display: inline-block;
}

.archive-text111 {
  display: inline-block;
}

.archive-text112 {
  display: inline-block;
}

.archive-text113 {
  display: inline-block;
}

.archive-text114 {
  display: inline-block;
}

.archive-text115 {
  display: inline-block;
}

.archive-text116 {
  display: inline-block;
}

.archive-text117 {
  display: inline-block;
}

.archive-text118 {
  display: inline-block;
}

.archive-text119 {
  display: inline-block;
}

.archive-text120 {
  display: inline-block;
}

.archive-text121 {
  display: inline-block;
}

.archive-text122 {
  display: inline-block;
}

.archive-text123 {
  display: inline-block;
}

.archive-text124 {
  display: inline-block;
}

.archive-text125 {
  display: inline-block;
}

.archive-text126 {
  display: inline-block;
}

.archive-text127 {
  display: inline-block;
}

.archive-text128 {
  display: inline-block;
}

.archive-text129 {
  display: inline-block;
}

.archive-text130 {
  display: inline-block;
}

.archive-text131 {
  display: inline-block;
}

.archive-text132 {
  display: inline-block;
}

.archive-text133 {
  display: inline-block;
}

.archive-text134 {
  display: inline-block;
}

.archive-text135 {
  display: inline-block;
}

.archive-text136 {
  display: inline-block;
}

.archive-text137 {
  display: inline-block;
}

.archive-text138 {
  display: inline-block;
}

.archive-text139 {
  display: inline-block;
}

.archive-text140 {
  display: inline-block;
}

.archive-text141 {
  display: inline-block;
}

.archive-text142 {
  display: inline-block;
}

.archive-text143 {
  display: inline-block;
}

.archive-text144 {
  display: inline-block;
}

.archive-text145 {
  display: inline-block;
}

.archive-text146 {
  display: inline-block;
}

.archive-text147 {
  display: inline-block;
}

.archive-text148 {
  display: inline-block;
}

.archive-text149 {
  display: inline-block;
}

.archive-text150 {
  display: inline-block;
}

.archive-text151 {
  display: inline-block;
}

.archive-text152 {
  display: inline-block;
}

.archive-text153 {
  display: inline-block;
}

.archive-text154 {
  display: inline-block;
}

.archive-text155 {
  display: inline-block;
}

.archive-text156 {
  display: inline-block;
}

.archive-text157 {
  display: inline-block;
}

.archive-text158 {
  display: inline-block;
}

.archive-text159 {
  display: inline-block;
}

.archive-text160 {
  display: inline-block;
}

.archive-text161 {
  display: inline-block;
}

.archive-text162 {
  display: inline-block;
}

.archive-text163 {
  display: inline-block;
}

.archive-text164 {
  display: inline-block;
}

.archive-text165 {
  display: inline-block;
}

.archive-text166 {
  display: inline-block;
}

.archive-text167 {
  display: inline-block;
}

.archive-text168 {
  display: inline-block;
}

.archive-text169 {
  display: inline-block;
}

.archive-text170 {
  display: inline-block;
}

.archive-text171 {
  display: inline-block;
}

.archive-text172 {
  display: inline-block;
}

.archive-text173 {
  display: inline-block;
}

.archive-text174 {
  display: inline-block;
}

.archive-text175 {
  display: inline-block;
}

.archive-text176 {
  display: inline-block;
}

.archive-text177 {
  display: inline-block;
}

.archive-text178 {
  display: inline-block;
}

.archive-text179 {
  display: inline-block;
}

.archive-text180 {
  display: inline-block;
}

.archive-text181 {
  display: inline-block;
}

.archive-text182 {
  display: inline-block;
}

.archive-text183 {
  display: inline-block;
}

.archive-text184 {
  display: inline-block;
}

.archive-text185 {
  display: inline-block;
}

.archive-text186 {
  display: inline-block;
}

.archive-text187 {
  display: inline-block;
}

.archive-text188 {
  display: inline-block;
}

.archive-text189 {
  display: inline-block;
}

.archive-text190 {
  display: inline-block;
}

.archive-text191 {
  display: inline-block;
}

.archive-text192 {
  display: inline-block;
}

.archive-text193 {
  display: inline-block;
}

.archive-text194 {
  display: inline-block;
}

.archive-text195 {
  display: inline-block;
}

.archive-text196 {
  display: inline-block;
}

.archive-text197 {
  display: inline-block;
}

.archive-text198 {
  display: inline-block;
}

.archive-text199 {
  display: inline-block;
}

.archive-text200 {
  display: inline-block;
}

.archive-text201 {
  display: inline-block;
}

.archive-text202 {
  display: inline-block;
}

.archive-text203 {
  display: inline-block;
}

.archive-text204 {
  display: inline-block;
}

.archive-text205 {
  display: inline-block;
}

.archive-text206 {
  display: inline-block;
}

.archive-text207 {
  display: inline-block;
}

.archive-text208 {
  display: inline-block;
}

.archive-text209 {
  display: inline-block;
}

.archive-text210 {
  display: inline-block;
}

.archive-text211 {
  display: inline-block;
}

.archive-text212 {
  display: inline-block;
}

.archive-text213 {
  display: inline-block;
}

.archive-text214 {
  display: inline-block;
}

.archive-text215 {
  display: inline-block;
}

.archive-text216 {
  display: inline-block;
}

.archive-text217 {
  display: inline-block;
}

.archive-text218 {
  display: inline-block;
}

.archive-text219 {
  display: inline-block;
}

.archive-text220 {
  display: inline-block;
}

.archive-text221 {
  display: inline-block;
}

.archive-text222 {
  display: inline-block;
}

.archive-text223 {
  display: inline-block;
}

.archive-text224 {
  display: inline-block;
}

.archive-text225 {
  display: inline-block;
}

.archive-text226 {
  display: inline-block;
}

.archive-text227 {
  display: inline-block;
}

.archive-text228 {
  display: inline-block;
}

.archive-text229 {
  display: inline-block;
}

.archive-text230 {
  display: inline-block;
}

.archive-text231 {
  display: inline-block;
}

.archive-text232 {
  display: inline-block;
}

.archive-text233 {
  display: inline-block;
}

.archive-text234 {
  display: inline-block;
}

.archive-text235 {
  display: inline-block;
}

.archive-text236 {
  display: inline-block;
}

.archive-text237 {
  display: inline-block;
}

.archive-text238 {
  display: inline-block;
}

.archive-text239 {
  display: inline-block;
}

.archive-text240 {
  display: inline-block;
}

.archive-text241 {
  display: inline-block;
}

.archive-text242 {
  display: inline-block;
}

.archive-text243 {
  display: inline-block;
}

.archive-text244 {
  display: inline-block;
}

.archive-text245 {
  display: inline-block;
}

.archive-text246 {
  display: inline-block;
}

.archive-text247 {
  display: inline-block;
}

.archive-text248 {
  display: inline-block;
}

@media (max-width: 1200px) {
  .archive-left-container {
    height: auto;
  }
}

@media (max-width: 991px) {
  .archive-body-container {
    flex-direction: column;
  }
  .archive-left-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-right: var(--dl-space-space-tenunits);
  }
  .archive-right-container {
    width: 100%;
    padding-left: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}

@media (max-width: 767px) {
  .archive-left-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .archive-text101 {
    width: 100%;
  }
  .archive-right-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
