.main-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
}
.projects-container {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  overflow: scroll;
  padding-top: 100px;
  padding-left: 35px;
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.archive-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}
.projects-table-container {
  overflow: scroll;
  width: 75%;
}
.projects-table {
  table-layout: auto;
}
table {
  border-collapse: collapse;
}
tr {
  border: solid var(--dl-color-feelers-dark);
  border-width: 1px 0;
}
tr:first-child {
  border-top: none;
}
tr:last-child {
  border-bottom: none;
}
td {
  padding-top: 5px;
  padding-bottom: 3px;
}
.projects-overview {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  /*position: fixed;*/
  right: 0;
  width: 100%;
}
.projects-header {
  font-family: ForPersonalGain-ForProfit;
  color: var(--dl-color-feelers-dark);
  font-weight: 400;
  text-align: left;
}
th.date {
  width: 15%;
}
th.title {
  width: 40%;
}
th.category {
  width: 25%;
}
th.status {
  width: 5%;
}
/* .overview-container {
  width: 90%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 15px;
  lex-direction: column;
} */
.overview-title {
  color: var(--dl-color-feelers-dark);
}
.overview-text {
  color: var(--dl-color-feelers-dark);
  width: 100%;
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  white-space: pre-wrap;
}
.overview-gallery {
  width: 100%;
  display: flex;
  z-index: 0;
  flex-direction: column;
}
.overview-image1 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.overview-image2 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.projects-cell-contents {
  color: var(--dl-color-feelers-dark); /*width: 8%;*/
  overflow: break-word;
}
.projects-cell-contents.active {
  color: var(--dl-color-feelers-dark);
  font-weight: bold;
  width: 8%;
} /*MOBILE AND SMALL
SCREENS*/
@media screen and (max-width: 700px) {
  .projects-container {
    display: flex;
    overflow: scroll;
    position: fixed;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
  }
  .projects-overview {
    position: relative;
    top: 15px;
    overflow: scroll;
    display: flex;
    width: 100%;
  }
  .projects-table-container {
    overflow-y: scroll;
    overflow-x: scroll;
    width: 100%;
    height: 30%;
    display: none;
  }
  .projects-table {
    table-layout: auto;
  }
  th.date {
    width: 15%;
  }
  th.title {
    width: 55%;
  }
  th.category {
    width: 5%;
  }
  th.status {
    width: 5%;
  }
} /*DESKTOP AND BIG SCREENS*/
@media only screen and (min-width: 601px) {
  .projects-overview {
    position: relative;
    top: 15px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 40%;
  }
}
