.reflective-essay-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}

.reflective-essay-navbar-container {
  width: 100%;
  display: flex;
  z-index: 4;
  position: fixed;
  flex-direction: column;
}

.reflective-essay-ressay {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}

.blue-sub-text {
  color: var(--dl-color-oe-pri-bue);
  z-index: 2;
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-tenunits);
}
.red-sub-text {
  color: var(--dl-color-oe-pri-red);
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-fiveunits);
}

.para-section {
  width: 100%;
  display: flex;
}

.para-section-col {
  width: 100%;
  display: flex;
}

.reflective-essay-ressay-r1l1 {
  width: 30%;
  display: flex;
  flex-direction: row;
}

.reflective-essay-image1 {
  width: 600px;
  margin-top: -600px;
  object-fit: contain;
}

.reflective-essay-ressay-r1r2 {
  width: 70%;
  display: flex;
  z-index: 2;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
}

.title-text {
  align-self: flex-end;
  margin-bottom: var(--dl-space-space-fiveunits);
}

.para1-4-container {
  display: flex;
  margin-bottom: var(--dl-space-space-tenunits);
  justify-content: flex-end;
}

.para-num {
  color: var(--dl-color-oe-pri-red);
  margin-right: 100px;
}

.para-text {
  width: 65%;
  text-align: justify;
}

.reflective-essay-ressay-r2r2 {
  width: 70%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.reflective-essay-ressay-r2r11 {
  display: flex;
  margin-left: var(--dl-space-space-tenunits);
  justify-content: flex-end;
}

.para-text-red {
  color: var(--dl-color-oe-pri-red);
}

.reflective-essay-ressay-r3l {
  width: 40%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.reflective-essay-image2 {
  width: 700px;
  object-fit: contain;
  margin-left: -80px;
}

.reflective-essay-ressay-r3r {
  width: 60%;
  display: flex;
  z-index: 2;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: flex-end;
}

.reflective-essay-ressay-r1r15 {
  display: flex;
  justify-content: flex-end;
}

.reflective-essay-text154 {
  width: 75%;
  text-align: justify;
}

.para-text-blue {
  color: var(--dl-color-oe-pri-bue);
}
.para-text-blue-link {
  color: var(--dl-color-oe-pri-bue);
  text-decoration: underline;
}
.para-text-blue-link:hover {
  color: var(--dl-color-oe-sec-yellow);
  text-decoration: underline;
}

.reflective-essay-ressay-r2r3 {
  width: 70%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.reflective-essay-ressay-r2r12 {
  display: flex;
  margin-left: var(--dl-space-space-tenunits);
  justify-content: flex-end;
}

.reflective-essay-ressay-r1l2 {
  width: 30%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.reflective-essay-image3 {
  margin-top: 100px;
  object-fit: contain;
  overflow: hidden;
}

.reflective-essay-ressay-r1r3 {
  width: 70%;
  display: flex;
  z-index: 2;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
}

.reflective-essay-ressay-r1r16 {
  display: flex;
  margin-bottom: 100px;
  justify-content: flex-end;
}

.reflective-essay-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 4;
  position: relative;
}

.reflective-essay-text206 {
  display: inline-block;
}

.reflective-essay-text207 {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-text {
    font-size: 100px;
  }
}

@media (max-width: 767px) {
  .reflective-essay-ressay-r1r2 {
    width: 100%;
    margin-left: -180px;
  }
  .title-text {
    font-size: 45px;
    padding-top: 50px;
  }
  .reflective-essay-ressay-r2r2 {
    width: 100%;
  }
  .reflective-essay-ressay-r2r11 {
    margin-left: 0;
  }
  .reflective-essay-ressay-r3r {
    width: 100%;
    margin-left: -140px;
  }
  .reflective-essay-ressay-r2r3 {
    width: 100%;
  }
  .reflective-essay-ressay-r2r12 {
    margin-left: 0;
  }
}

@media (max-width: 479px) {
  .reflective-essay-ressay {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .para-section-col {
    flex-direction: column;
  }
  .reflective-essay-ressay-r1l1 {
    width: 60%;
  }
  .reflective-essay-image1 {
    width: 100%;
    margin-top: -250px;
    margin-left: 60px;
  }
  .reflective-essay-ressay-r1r2 {
    margin-top: -300px;
    margin-left: 0;
    padding-top: 0;
  }
  .title-text {
    align-self: center;
  }

  .blue-sub-text {
    font-size: 16px;
  }
  .red-sub-text {
    font-size: 16px;
  }
  .reflective-essay-ressay-r1r11 {
    justify-content: space-between;
  }
  .para-num {
    font-size: 40px;
    margin-right: 0;
  }
  .para-text {
    width: 80%;
    font-size: 16px;
  }
  .para1-4-container {
    justify-content: space-between;
  }

  .reflective-essay-ressay-r2r11 {
    justify-content: space-between;
  }

  .reflective-essay-text154 {
    width: 100%;
  }
  .para-text-blue {
    font-size: 16px;
  }
  .para-text-blue-link {
    font-size: 16px;
  }
  .reflective-essay-ressay-r2r12 {
    justify-content: space-between;
  }
  .reflective-essay-ressay-r1r3 {
    width: 100%;
    margin-left: -110px;
  }
  .reflective-essay-ressay-r1r16 {
    justify-content: space-between;
  }
}
