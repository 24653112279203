.to-dwell-a-little-longer-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.to-dwell-a-little-longer-editorial-bg-container {
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: var(--dl-color-oe-bg);
}

.to-dwell-a-little-longer-editorial-bg-l {
  gap: var(--dl-space-space-tenunits);
  display: flex;
  flex-direction: row;
}

.to-dwell-a-little-longer-image10 {
  object-fit: contain;
}

.to-dwell-a-little-longer-image11 {
  object-fit: contain;
}

.to-dwell-a-little-longer-image12 {
  object-fit: contain;
}

.to-dwell-a-little-longer-image13 {
  object-fit: contain;
}

.to-dwell-a-little-longer-navbar-container {
  width: 100%;
  display: flex;
  z-index: 4;
  position: fixed;
  flex-direction: column;
}

.to-dwell-a-little-longer-editorial-r1 {
  width: 100%;
  display: flex;
  position: relative;
  overflow-x: hidden;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}

.to-dwell-a-little-longer-editorial-body {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  z-index: 2;
  align-self: center;
  flex-direction: column;
  justify-content: center;
}

.main-title {
  margin-bottom: var(--dl-space-space-fiveunits);
}

.blue-text {
  color: var(--dl-color-oe-pri-bue);
  z-index: 2;
  text-align: right;
  margin-right: 220px;
}

.to-dwell-a-little-longer-image14 {
  width: 800px;
  transform: rotateY(180deg);
  margin-top: -120px;
  object-fit: cover;
  margin-left: -350px;
}

.quote-text {
  color: var(--dl-color-oe-pri-red);
  width: 60%;
  margin-left: 120px;
  margin-bottom: var(--dl-space-space-doubleunit);
}

.quote-credit-text {
  width: 25%;
  margin-left: 120px;
}

.body-text-link {
  text-decoration: underline;
}
.body-text-link:hover {
  color: var(--dl-color-oe-sec-yellow);
}

.quote-credit-link {
  color: var(--dl-color-oe-base);
  width: 25%;
  margin-left: 120px;
  text-decoration: underline;
}

.quote-credit-link:hover {
  color: var(--dl-color-oe-sec-yellow);
}

.to-dwell-a-little-longer-image15 {
  width: 800px;
  align-self: flex-end;
  margin-top: -100px;
  object-fit: cover;
  margin-right: -180px;
}

.to-dwell-a-little-longer-editotial-r1 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}

.to-dwell-a-little-longer-editorial-r1l {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.to-dwell-a-little-longer-image16 {
  width: 100%;
  object-fit: contain;
}

.to-dwell-a-little-longer-text114 {
  color: var(--dl-color-oe-base);
  width: 40%;
  margin-top: var(--dl-space-space-tripleunit);
}

.to-dwell-a-little-longer-editorial-r1r {
  gap: var(--dl-space-space-tenunits);
  width: 50%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.body-para-text {
  text-align: justify;
}

.red-text {
  color: var(--dl-color-oe-pri-red);
}

.to-dwell-a-little-longer-linksplitter {
  display: flex;
  align-items: flex-start;
}

.to-dwell-a-little-longer-link2 {
  text-decoration: underline;
}

.to-dwell-a-little-longer-link2:hover {
  color: var(--dl-color-oe-sec-yellow);
}

.to-dwell-a-little-longer-editorial-r2 {
  width: 25%;
  display: flex;
  align-self: center;
  flex-direction: column;
}

.to-dwell-a-little-longer-image17 {
  object-fit: contain;
}

.to-dwell-a-little-longer-text131 {
  color: var(--dl-color-oe-base);
  width: 50%;
  margin-top: var(--dl-space-space-tripleunit);
}

.to-dwell-a-little-longer-editorial-r3 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}

.to-dwell-a-little-longer-editorial-r3l1 {
  width: 40%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.to-dwell-a-little-longer-image18 {
  width: 180px;
  object-fit: contain;
}

.to-dwell-a-little-longer-editorial-r3r1 {
  gap: var(--dl-space-space-tenunits);
  width: 50%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.to-dwell-a-little-longer-editorial-r4 {
  width: 100%;
  display: flex;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: space-between;
}

.to-dwell-a-little-longer-editorial-r4l {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.to-dwell-a-little-longer-image19 {
  width: 50%;
  object-fit: contain;
}

.to-dwell-a-little-longer-text143 {
  color: var(--dl-color-oe-base);
  width: 35%;
  margin-top: var(--dl-space-space-tripleunit);
}

.to-dwell-a-little-longer-editorial-r4r {
  gap: var(--dl-space-space-tenunits);
  width: 50%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.to-dwell-a-little-longer-text155 {
  color: var(--dl-color-oe-pri-red);
  width: 60%;
  align-self: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}

.to-dwell-a-little-longer-image20 {
  width: 100px;
  transform: rotate(90deg);
  align-self: flex-end;
  margin-top: -400px;
  object-fit: cover;
  margin-right: 300px;
  margin-bottom: -300px;
}

.to-dwell-a-little-longer-editorial-r5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.to-dwell-a-little-longer-editorial-r5l {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.to-dwell-a-little-longer-image21 {
  width: 80%;
  object-fit: contain;
  margin-bottom: 300px;
}

.to-dwell-a-little-longer-image22 {
  object-fit: contain;
}

.to-dwell-a-little-longer-text158 {
  color: var(--dl-color-oe-base);
  width: 40%;
  margin-top: var(--dl-space-space-tripleunit);
}

.to-dwell-a-little-longer-editorial-r5r {
  gap: var(--dl-space-space-tenunits);
  width: 50%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.to-dwell-a-little-longer-text189 {
  color: var(--dl-color-oe-pri-red);
  width: 60%;
  margin-top: var(--dl-space-space-tenunits);
  margin-left: 120px;
  margin-bottom: var(--dl-space-space-tenunits);
}

.to-dwell-a-little-longer-editorial-r6 {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-tenunits);
  justify-content: space-between;
}

.to-dwell-a-little-longer-editorial-r3l2 {
  width: 40%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.to-dwell-a-little-longer-image23 {
  width: 55%;
  object-fit: contain;
}

.to-dwell-a-little-longer-editorial-r3r2 {
  gap: var(--dl-space-space-tenunits);
  width: 50%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.to-dwell-a-little-longer-text206 {
  font-size: 20px;
  font-family: "Archivo Narrow";
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
}

.to-dwell-a-little-longer-editorial-r7 {
  width: 35%;
  display: flex;
  align-self: center;
  margin-top: -300px;
  flex-direction: column;
}

.to-dwell-a-little-longer-image24 {
  object-fit: contain;
}

.to-dwell-a-little-longer-text209 {
  color: var(--dl-color-oe-base);
  width: 50%;
  margin-top: var(--dl-space-space-tripleunit);
}

.body-para-text-alt {
  fill: var(--dl-color-oe-base);
  color: var(--dl-color-oe-base);
  width: 50%;
  align-self: flex-end;
  text-align: justify;
  padding-top: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-tenunits);
}

.to-dwell-a-little-longer-editorial-r8 {
  width: 70%;
  display: flex;
  align-self: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}

.to-dwell-a-little-longer-image25 {
  object-fit: contain;
}

.to-dwell-a-little-longer-text227 {
  color: var(--dl-color-oe-base);
  width: 20%;
  margin-top: var(--dl-space-space-tripleunit);
}

.body-para-text-cen {
  color: var(--dl-color-oe-base);
  width: 50%;
  align-self: center;
  text-align: justify;
  padding-top: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-tenunits);
}
.body-para-text-end {
  color: var(--dl-color-oe-base);
  width: 50%;
  align-self: flex-end;
  text-align: justify;
  padding-top: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-tenunits);
}

.to-dwell-a-little-longer-editorial-r9 {
  height: 500px;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}

.to-dwell-a-little-longer-image26 {
  object-fit: contain;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
}

.to-dwell-a-little-longer-image27 {
  object-fit: contain;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
}

.to-dwell-a-little-longer-image28 {
  object-fit: contain;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
}

.to-dwell-a-little-longer-image29 {
  object-fit: contain;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
}

.to-dwell-a-little-longer-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 4;
  position: relative;
}

.to-dwell-a-little-longer-text236 {
  display: inline-block;
}

.to-dwell-a-little-longer-text237 {
  display: inline-block;
}

@media (max-width: 1200px) {
  .to-dwell-a-little-longer-editorial-r7 {
    margin-top: -100px;
  }
}

@media (max-width: 991px) {
  .to-dwell-a-little-longer-editorial-r1 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .to-dwell-a-little-longer-editorial-r7 {
    margin-top: var(--dl-space-space-fiveunits);
  }
}

@media (max-width: 767px) {
  .to-dwell-a-little-longer-image15 {
    margin-top: 0;
  }
}

@media (max-width: 479px) {
  .to-dwell-a-little-longer-editorial-bg-l {
    gap: var(--dl-space-space-unit);
  }
  .main-title {
    font-size: 70px;
  }
  .blue-text {
    font-size: 16px;
    margin-right: 0;
  }
  .to-dwell-a-little-longer-image14 {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
  .quote-text {
    width: 80%;
    font-size: 16px;
    margin-left: 0;
  }
  .quote-credit-text {
    width: 70%;
    font-size: 12px;
    margin-left: 0;
  }
  .quote-credit-link {
    width: 70%;
    font-size: 12px;
    margin-left: 0;
  }
  .body-text-link {
    font-size: 16px;
  }
  .to-dwell-a-little-longer-image15 {
    width: 100%;
    margin-right: 0;
  }
  .to-dwell-a-little-longer-editotial-r1 {
    gap: var(--dl-space-space-tenunits);
    flex-direction: column;
  }
  .to-dwell-a-little-longer-editorial-r1l {
    width: 70%;
  }
  .to-dwell-a-little-longer-text114 {
    width: 50%;
    font-size: 12px;
  }
  .to-dwell-a-little-longer-editorial-r1r {
    width: 90%;
    align-self: flex-end;
  }
  .body-para-text {
    font-size: 16px;
  }
  .to-dwell-a-little-longer-linksplitter {
    flex-direction: column;
  }
  .to-dwell-a-little-longer-editorial-r2 {
    width: 65%;
  }
  .to-dwell-a-little-longer-text131 {
    width: 60%;
    font-size: 12px;
  }
  .to-dwell-a-little-longer-editorial-r3 {
    margin-bottom: var(--dl-space-space-tenunits);
    flex-direction: column;
  }
  .to-dwell-a-little-longer-editorial-r3r1 {
    width: 90%;
    align-self: flex-end;
    margin-top: -650px;
  }
  .to-dwell-a-little-longer-editorial-r4 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .to-dwell-a-little-longer-editorial-r4l {
    width: 65%;
  }
  .to-dwell-a-little-longer-image19 {
    width: 100%;
  }
  .to-dwell-a-little-longer-text143 {
    width: 50%;
    font-size: 12px;
  }
  .to-dwell-a-little-longer-editorial-r4r {
    width: 90%;
    align-self: flex-end;
  }
  .to-dwell-a-little-longer-text155 {
    width: 80%;
    font-size: 16px;
    align-self: flex-start;
  }
  .to-dwell-a-little-longer-editorial-r5 {
    gap: var(--dl-space-space-tenunits);
    flex-direction: column;
  }
  .to-dwell-a-little-longer-editorial-r5l {
    width: 65%;
    align-self: center;
  }
  .to-dwell-a-little-longer-image21 {
    display: none;
    margin-bottom: 0;
  }
  .to-dwell-a-little-longer-image22 {
    width: 100%;
  }
  .to-dwell-a-little-longer-text158 {
    width: 50%;
    font-size: 12px;
  }
  .to-dwell-a-little-longer-editorial-r5r {
    width: 90%;
    align-self: flex-end;
  }
  .to-dwell-a-little-longer-text189 {
    width: 80%;
    font-size: 16px;
    margin-left: 0;
  }
  .to-dwell-a-little-longer-editorial-r6 {
    flex-direction: column;
  }
  .to-dwell-a-little-longer-editorial-r3l2 {
    width: 50%;
    align-items: flex-start;
  }
  .to-dwell-a-little-longer-image23 {
    width: 100%;
  }
  .to-dwell-a-little-longer-editorial-r3r2 {
    width: 90%;
    align-self: flex-end;
    margin-top: -600px;
  }
  .to-dwell-a-little-longer-editorial-r7 {
    width: 65%;
    align-self: flex-start;
    margin-top: var(--dl-space-space-tenunits);
  }
  .to-dwell-a-little-longer-text209 {
    font-size: 12px;
  }
  .body-para-text-alt {
    width: 90%;
    font-size: 16px;
  }
  .to-dwell-a-little-longer-editorial-r8 {
    width: 90%;
  }
  .to-dwell-a-little-longer-text227 {
    width: 40%;
    font-size: 12px;
  }
  .body-para-text-cen {
    width: 90%;
    font-size: 16px;
    align-self: flex-end;
  }
  .body-para-text-end {
    width: 90%;
    font-size: 16px;
    align-self: flex-end;
  }
  .to-dwell-a-little-longer-editorial-r9 {
    height: 200px;
  }
}
