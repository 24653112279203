.privacy-policy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.privacy-policy-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}
 
.privacy-policy-image1 {
  top: 12%;
  left: 10%;
  width: 350px;
  position: absolute;
  object-fit: cover;
}
 
.privacy-policy-image2 {
  top: 40%;
  right: -6%;
  width: 300px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
 
.privacy-policy-image3 {
  left: -10%;
  width: 300px;
  bottom: 0%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}
 
.privacy-policy-navbar-container {
  width: 100%;
  display: flex;
  z-index: 2;
  position: fixed;
  flex-direction: column;
}
 
.privacy-policy-body-container {
  width: 100%;
  display: flex;
  padding: 100px;
  z-index: 1;
  position: relative;
  flex-direction: column;
}
 
.privacy-policy-header {
  color: var(--dl-color-feelers-dark);
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.privacy-policy-col-container {
  display: flex;
  flex-direction: row;
}
 
.privacy-policy-col1-container {
  width: 45%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.privacy-policy-text100 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text101 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-text108 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text109 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-text182 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text183 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-col2-container {
  width: 45%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.privacy-policy-text234 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text235 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-text256 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text257 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-text268 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text269 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-text274 {
  color: var(--dl-color-feelers-dark);
  font-size: 50px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  padding-top: 100px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.privacy-policy-text275 {
  color: var(--dl-color-feelers-dark);
}
 
.privacy-policy-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 2;
  position: relative;
}
 
.privacy-policy-text280 {
  display: inline-block;
}
 
.privacy-policy-text281 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .privacy-policy-header {
    font-size: 120px;
  }
  .privacy-policy-col-container {
    flex-direction: column;
  }
  .privacy-policy-col1-container {
    width: 100%;
  }
  .privacy-policy-col2-container {
    width: 100%;
  }
}
 
@media(max-width: 479px) {
  .privacy-policy-body-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
