.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.home-bg-container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: fixed;
  background-color: var(--dl-color-oe-bg);
}

.home-image10 {
  left: 0px;
  height: 100vh;
  position: absolute;
  align-self: flex-start;
  object-fit: cover;
}

.home-image11 {
  right: 0px;
  height: 100vh;
  position: absolute;
  align-self: flex-end;
  object-fit: cover;
}

.home-navbar-container {
  width: 100%;
  display: flex;
  z-index: 4;
  position: fixed;
  flex-direction: column;
}

.home-landingessay {
  width: 100%;
  height: 200vh;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.home-image12 {
  top: var(--dl-space-space-fiveunits);
  height: 90vh;
  z-index: 0;
  position: fixed;
  align-self: center;
  object-fit: cover;
  opacity: 0.4;
}

.home-landing {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}

.home-image13 {
  width: 1600px;
  align-self: center;
  margin-top: -80px;
  object-fit: cover;
}

.home-landing-row {
  width: 100%;
  display: flex;
  margin-top: -100px;
  justify-content: space-between;
}

.home-landing-l {
  width: 75%;
  height: 70vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.home-text100 {
  margin-bottom: var(--dl-space-space-fiveunits);
}

.home-image14 {
  width: 30px;
  object-fit: cover;
}

.home-landing-r {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}

.home-image15 {
  width: 150px;
  object-fit: contain;
}

.home-essay {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
}

.home-essay-body {
  gap: 270px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.home-essay-bg {
  width: 30%;
  display: flex;
  flex-direction: row;
}

.home-image16 {
  object-fit: contain;
  opacity: 0.4;
}

.home-essay-text {
  gap: var(--dl-space-space-doubleunit);
  width: 35%;
  display: flex;
  flex-direction: column;
}

.home-text101 {
  text-align: justify;
  margin-bottom: var(--dl-space-space-fiveunits);
}

.home-navlink1 {
  color: var(--dl-color-oe-pri-bue);
  align-self: flex-end;
  text-decoration: underline;
  z-index: 4;
}

.home-navlink1:hover {
  color: var(--dl-color-oe-pri-red);
}

.home-image17 {
  width: 120px;
  position: relative;
  transform: rotate(90deg);
  margin-top: -700px;
  object-fit: cover;
  margin-left: 600px;
}

.home-editorial {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}

.home-editorial-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
}

.home-editorial-bg-l {
  gap: var(--dl-space-space-tenunits);
  height: 100vh;
  display: flex;
  z-index: 2;
  flex-direction: row;
}

.home-image18 {
  object-fit: contain;
}

.home-image19 {
  object-fit: contain;
}

.home-image20 {
  object-fit: contain;
}

.home-image21 {
  object-fit: contain;
}

.home-image22 {
  width: 170px;
  align-self: flex-end;
  object-fit: cover;
}

.home-mask1 {
  left: 0px;
  right: 0px;
  width: 170px;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: var(--dl-color-oe-bg);
}

.home-editorial-body {
  width: 100%;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.home-text109 {
  text-align: right;
  margin-right: 140px;
}

.program-head-text {
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunits);
}

.home-text117 {
  width: 30%;
  align-self: flex-end;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: justify;
  margin-right: 220px;
  margin-bottom: var(--dl-space-space-fiveunits);
}

.home-navlink2 {
  color: var(--dl-color-oe-pri-bue);
  align-self: flex-end;
  margin-right: 220px;
  text-decoration: underline;
}

.home-navlink2:hover {
  color: var(--dl-color-oe-pri-red);
}

.home-program1 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}

.home-program1-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
}

.home-program1-bg-l {
  gap: var(--dl-space-space-tenunits);
  display: flex;
  z-index: 3;
  flex-direction: row;
}

.home-image23 {
  object-fit: contain;
}

.home-image24 {
  object-fit: contain;
}

.home-image25 {
  height: 350px;
  z-index: 2;
  margin-top: 160px;
  object-fit: contain;
  opacity: 0.4;
}

.home-mask2 {
  left: 0px;
  right: 0px;
  width: 170px;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: var(--dl-color-oe-bg);
}

.home-program1-body {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.home-text122 {
  text-align: right;
  margin-right: 140px;
}

.home-program1-body-r2 {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

.home-text128 {
  color: var(--dl-color-oe-pri-bue);
  margin-right: var(--dl-space-space-tenunits);
}

.home-text145 {
  width: 30%;
  text-align: justify;
  margin-right: 220px;
}

.home-program2 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}

.home-program2-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  flex-direction: row;
}

.home-image26 {
  z-index: 2;
  object-fit: contain;
  margin-left: 120px;
  opacity: 0.5;
}

.home-mask3 {
  left: 0px;
  right: 0px;
  width: 170px;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: var(--dl-color-oe-bg);
}

.home-program2-body {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.home-text146 {
  text-align: right;
  margin-right: 140px;
}

.home-text150 {
  width: 40%;
  align-self: center;
  text-align: justify;
}

.home-program2-body-r2 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunits);
}

.home-program2-body-c2 {
  display: flex;
  align-items: flex-end;
  margin-right: 180px;
  flex-direction: column;
  justify-content: space-between;
}

.home-text156 {
  width: 50%;
  text-align: justify;
}

.home-text157 {
  color: var(--dl-color-oe-pri-bue);
}

.home-program3 {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}

.home-program3-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
}

.home-image27 {
  z-index: 2;
  object-fit: contain;
  margin-left: var(--dl-space-space-fiveunits);
  opacity: 0.4;
}

.home-image28 {
  width: 170px;
  align-self: flex-end;
  object-fit: contain;
}

.home-mask4 {
  left: 0px;
  right: 0px;
  width: 170px;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: var(--dl-color-oe-bg);
}

.home-program3-body {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.home-text160 {
  text-align: right;
  margin-right: 140px;
}

.home-program3-body-r2 {
  display: flex;
  margin-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-end;
}

.home-text166 {
  color: var(--dl-color-oe-pri-bue);
  margin-right: var(--dl-space-space-tenunits);
}

.home-text182 {
  width: 30%;
  text-align: justify;
  margin-right: 220px;
}

.home-link1 {
  color: var(--dl-color-oe-pri-bue);
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-right: 220px;
  text-decoration: underline;
}

.home-link1:hover {
  color: var(--dl-color-oe-pri-red);
}

.home-program4 {
  gap: 300px;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}

.home-program4-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
}

.home-image29 {
  object-fit: cover;
  margin-left: var(--dl-space-space-fiveunits);
  opacity: 0.5;
  z-index: 2;
}

.home-image30 {
  width: 170px;
  align-self: flex-start;
  object-fit: contain;
}

.home-mask5 {
  left: 0px;
  right: 0px;
  width: 170px;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: var(--dl-color-oe-bg);
}

.home-program4-body {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.home-text190 {
  text-align: right;
  margin-right: 140px;
}

.home-program4-body-r21 {
  display: flex;
  justify-content: flex-end;
}

.home-text196 {
  color: var(--dl-color-oe-pri-bue);
  margin-right: var(--dl-space-space-tenunits);
}

.home-text212 {
  width: 30%;
  text-align: justify;
  margin-right: 220px;
}

.home-link2 {
  color: var(--dl-color-oe-pri-bue);
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-right: 220px;
  text-decoration: underline;
}

.home-link2:hover {
  color: var(--dl-color-oe-pri-red);
}

.home-program5 {
  display: flex;
  max-width: 100%;
  align-items: center;
}

.home-image31 {
  z-index: 2;
  object-fit: cover;
  margin-bottom: -580px;
  opacity: 0.3;
}

.home-mask6 {
  width: 170px;
  height: 50vh;
  display: flex;
  z-index: 1;
  margin-top: -140px;
  background-color: var(--dl-color-oe-bg);
}

.home-closing {
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 3;
  position: relative;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}

.home-closing-bg {
  width: 30%;
  height: 100vh;
  display: flex;
  z-index: 3;
  position: absolute;
  flex-direction: row;
  justify-content: flex-start;
}

.home-image32 {
  object-fit: contain;
  width: 1000px;
  margin-right: -300px;
}

.home-closing-body {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  z-index: 3;
  flex-direction: column;
  justify-content: center;
}

.home-text223 {
  text-align: right;
  margin-right: 140px;
}

.program-head-text-centre {
  align-self: center;
}

.home-program4-body-r22 {
  display: flex;
  justify-content: flex-end;
}

.home-text229 {
  color: var(--dl-color-oe-pri-bue);
  margin-right: var(--dl-space-space-tenunits);
}

.home-text245 {
  width: 40%;
  text-align: justify;
  margin-right: var(--dl-space-space-fiveunits);
}

.home-link3 {
  color: var(--dl-color-oe-pri-bue);
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-fiveunits);
  text-decoration: underline;
}

.home-link3:hover {
  color: var(--dl-color-oe-pri-red);
}

.home-mask7 {
  left: 0px;
  right: 0px;
  width: 170px;
  height: 100vh;
  margin: auto;
  display: flex;
  z-index: 1;
  position: absolute;
  background-color: var(--dl-color-oe-bg);
}

.home-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 4;
  position: relative;
}

.home-text256 {
  display: inline-block;
}

.home-text257 {
  display: inline-block;
}

@media (max-width: 1200px) {
  .home-text109 {
    margin-right: 0;
  }
  .home-text122 {
    margin-right: 0;
  }
  .home-text146 {
    margin-right: 0;
  }
  .home-program2-body-c2 {
    margin-right: 0;
  }
  .home-text156 {
    width: 70%;
  }
  .home-text160 {
    margin-right: 0;
  }
  .home-text190 {
    margin-right: 0;
  }
  .home-text223 {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .home-image13 {
    width: 1000px;
  }
  .home-landing-row {
    margin-top: -60px;
  }
  .home-text117 {
    width: 70%;
  }
  .home-text182 {
    width: 70%;
    margin-right: 0;
  }
  .home-link1 {
    margin-right: 0;
  }
  .home-text212 {
    width: 70%;
    align-self: flex-end;
    margin-right: 0;
  }
  .home-link2 {
    margin-right: 0;
  }
  .home-text245 {
    margin-right: 0;
  }
  .home-link3 {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .home-essay-body {
    flex-direction: column;
  }
  .home-essay-bg {
    align-self: flex-start;
  }
  .home-essay-text {
    width: 70%;
    align-self: flex-end;
    margin-top: -700px;
  }
  .home-editorial-bg-l {
    gap: var(--dl-space-space-fiveunits);
  }
  .home-image22 {
    display: none;
  }
  .program-head-text {
    font-size: 100px;
  }
  .home-text117 {
    margin-right: 0;
  }
  .home-navlink2 {
    margin-right: 0;
  }
  .home-program1-body-r2 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-text145 {
    width: 70%;
    align-self: flex-end;
    margin-right: 0;
  }

  .home-text150 {
    width: 70%;
    align-self: flex-end;
  }
  .home-program2-body-r2 {
    flex-direction: column-reverse;
  }

  .home-program2-body-c2 {
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-program3-body-r2 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-text182 {
    align-self: flex-end;
  }

  .home-program4-body-r21 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }

  .home-program4-body-r22 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-text245 {
    width: 70%;
    align-self: flex-end;
    margin-right: var(--dl-space-space-fiveunits);
  }
}

@media (max-width: 479px) {
  .home-landing {
    justify-content: flex-end;
  }
  .home-image13 {
    width: 400px;
  }
  .home-landing-row {
    margin-top: 0;
  }
  .home-landing-l {
    height: 40vh;
  }
  .home-landing-r {
    align-self: flex-start;
  }
  .home-essay-bg {
    width: 100%;
  }
  .home-image16 {
    width: 90%;
  }
  .home-text101 {
    font-size: 16px;
  }
  .home-navlink1 {
    font-size: 14px;
  }
  .home-editorial-bg-l {
    gap: var(--dl-space-space-halfunit);
  }
  .home-editorial-body {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-start;
  }
  .home-text109 {
    font-size: 20px;
  }
  .program-head-text {
    font-size: 50px;
  }
  .home-text117 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-navlink2 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-program1-bg {
    justify-content: unset;
    height: 100%;
  }
  .home-program2-bg {
    height: 100%;
  }
  .home-program3-bg {
    height: 100%;
  }
  .home-program4-bg {
    height: 100%;
  }
  .home-program5-bg {
    height: 100%;
  }
  .home-closing-bg {
    height: 100%;
  }
  .home-landingessay {
    height: 240vh;
  }
  .home-program1 {
    height: 120vh;
  }
  .home-program2 {
    height: 140vh;
  }
  .home-program3 {
    height: 140vh;
  }
  .home-program4 {
    height: 160vh;
  }
  .home-closing {
    height: 140vh;
  }

  .home-program1-bg-l {
    gap: var(--dl-space-space-unit);
  }
  .home-image25 {
    height: 200px;
    margin-left: -150px;
  }
  .home-text122 {
    font-size: 20px;
  }

  .home-text128 {
    font-size: 16px;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: 0;
  }
  .home-text145 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-image26 {
    margin-left: 0;
  }
  .home-text146 {
    font-size: 20px;
  }

  .home-text150 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }

  .home-program2-body-c2 {
    gap: var(--dl-space-space-fiveunits);
  }
  .home-text156 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-text157 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-image27 {
    margin-left: 0;
  }
  .home-image28 {
    display: none;
  }
  .home-text160 {
    font-size: 20px;
  }

  .home-program3-body-r2 {
    margin-bottom: 0;
  }
  .home-text166 {
    font-size: 16px;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: 0;
  }
  .home-text182 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-link1 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-image30 {
    display: none;
  }
  .home-text190 {
    font-size: 20px;
  }

  .home-text196 {
    font-size: 16px;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: 0;
  }
  .home-text212 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-link2 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }

  .home-image31 {
    width: 900px;
    margin-top: 0;
    margin-left: -200px;
    margin-bottom: 0;
    opacity: 0.3;
  }
  .home-mask6 {
    height: 60vh;
    margin-top: -500px;
  }
  .home-image32 {
    width: 100%;
  }
  .home-text223 {
    font-size: 20px;
  }
  .program-head-text-bigger {
    font-size: 58px;
  }
  .home-text229 {
    font-size: 16px;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: 0;
  }
  .home-text245 {
    font-size: 16px;
  }
  .home-text100 {
    font-size: 20px;
  }
  .home-link3 {
    font-size: 16px;
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-footer-container {
    margin-top: 200px;
  }
}
