.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.about-bg-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-direction: row;
}

.about-image1 {
  top: -12%;
  left: 32%;
  width: 300px;
  position: absolute;
  object-fit: cover;
}

.about-image2 {
  top: 22%;
  right: 0%;
  width: 350px;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}

.about-image3 {
  right: 30%;
  width: 400px;
  bottom: 5%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
}

.about-navbar-container {
  width: 100%;
  display: flex;
  z-index: 3;
  position: fixed;
  flex-direction: column;
}

.about-body-container {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.about-top-container {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-end;
}

.about-header-container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.about-top-row {
  display: flex;
}

.about-image4 {
  width: 300px;
  z-index: 2;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
  margin-bottom: -30px;
}

.about-heading1 {
  color: var(--dl-color-feelers-dark);
}

.about-heading2 {
  color: var(--dl-color-feelers-dark);
  margin-bottom: var(--dl-space-space-unit);
}

.about-heading3 {
  color: var(--dl-color-feelers-dark);
}

.about-bottom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.about-title-container {
  display: flex;
  padding-left: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
}

.about-heading4 {
  color: var(--dl-color-feelers-dark);
}

.about-row1 {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}

.about-profile-slot10 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-socials1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  flex-direction: row;
}

.about-link1 {
  display: contents;
}

.about-website1 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.about-website1:hover {
  fill: var(--dl-color-feelers-accent);
}

.about-link2 {
  display: contents;
}

.about-instagram1 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.about-instagram1:hover {
  fill: var(--dl-color-feelers-accent);
}

.about-profile-slot11 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-profile-slot12 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-socials2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  flex-direction: row;
}

.about-link3 {
  display: contents;
}

.about-instagram2 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
  text-decoration: none;
}

.about-instagram2:hover {
  fill: var(--dl-color-feelers-accent);
}

.about-profile-slot13 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-socials3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  flex-direction: row;
}

.about-link4 {
  display: contents;
}

.about-website2 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
  text-decoration: none;
}

.about-website2:hover {
  fill: var(--dl-color-feelers-accent);
}

.about-link5 {
  display: contents;
}

.about-instagram3 {
  fill: var(--dl-color-feelers-dark);
  width: 20px;
  height: 20px;
  transition: 0.3s;
  text-decoration: none;
}

.about-instagram3:hover {
  fill: var(--dl-color-feelers-accent);
}

.about-profile-slot14 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-row2 {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}

.about-profile-slot15 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-profile-slot16 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-profile-slot17 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-profile-slot18 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-profile-slot19 {
  color: var(--dl-color-feelers-dark);
  width: 20%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.about-row3 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: flex-end;
}

.about-text-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.about-text12 {
  color: var(--dl-color-feelers-dark);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-tripleunit);
}

.about-link6 {
  color: var(--dl-color-feelers-dark);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}

.about-link6:hover {
  color: var(--dl-color-feelers-accent);
}

.about-footer-container {
  width: 100%;
  bottom: 0;
  z-index: 3;
  position: relative;
}

.about-text26 {
  display: inline-block;
}

.about-text27 {
  display: inline-block;
}

@media (max-width: 1200px) {
  .about-row1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}

@media (max-width: 767px) {
  .about-body-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .about-top-container {
    justify-content: flex-start;
  }
  .about-top-row {
    flex-direction: column;
  }
  .about-row1 {
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .about-profile-slot10 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot11 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot12 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot13 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot14 {
    width: 100%;
    display: none;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-row2 {
    flex-direction: column;
  }
  .about-profile-slot15 {
    width: 100%;
    display: none;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot16 {
    width: 100%;
    display: none;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot17 {
    width: 100%;
    display: none;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot18 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot19 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-row3 {
    flex-direction: column;
  }
  .about-text-container {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .about-body-container {
    padding-top: 100px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .about-top-row {
    flex-direction: column;
  }
  .about-heading1 {
    font-size: 66px;
  }
  .about-heading2 {
    font-size: 66px;
  }
  .about-heading3 {
    font-size: 66px;
  }
  .about-profile-slot11 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot12 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot13 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot14 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot15 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot16 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot17 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot18 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-profile-slot19 {
    width: 100%;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-row3 {
    flex-direction: column;
  }
  .about-text-container {
    width: 100%;
  }
}
