.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
 
.footer-copyright-container {
  width: 100%;
  display: flex;
  position: relative;
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
}
 
.footer-link1 {
  color: var(--dl-color-feelers-dark);
  z-index: 1;
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.footer-link2 {
  color: var(--dl-color-feelers-dark);
  z-index: 1;
  align-self: flex-start;
  transition: 0.3s;
  text-decoration: none;
}
 
.footer-link2:hover {
  color: var(--dl-color-feelers-accent);
}
 
.footer-text1 {
  display: inline-block;
}
 
.footer-text2 {
  display: inline-block;
}
 
.footerroot-class-name {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name1 {
  height: 100%;
}
 
.footerroot-class-name2 {
  height: 100%;
}
 
.footerroot-class-name3 {
  height: 100%;
}
 
.footerroot-class-name4 {
  height: 100%;
  max-width: auto;
}
 
.footerroot-class-name5 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name6 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name7 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name8 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name9 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name10 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name11 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name12 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name13 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name14 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name15 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name16 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name17 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name18 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name19 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name20 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name21 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name22 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name23 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name24 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name25 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name26 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name27 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name28 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 

 
.footerroot-class-name30 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
.footerroot-class-name31 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
 
@media(max-width: 767px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-copyright-container {
    flex-direction: column;
  }
}
 
@media(max-width: 479px) {
  .footer-footer {
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .footer-copyright-container {
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
}
