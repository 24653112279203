.overview-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 50px;
  overflow-y: scroll;
}
.overview-container-mobile {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 55px;
  overflow-y: visible;
}
.overview-title {
  color: var(--dl-color-feelers-dark);
}
.overview-text {
  color: var(--dl-color-feelers-dark);
  width: 100%;
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.overview-gallery {
  width: 100%;
  display: flex;
  z-index: 0;
  flex-direction: column;
}
.overview-image1 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.overview-image2 {
  width: 100%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-doubleunit);
}
@media only screen and (max-width: 700px) {
  .overview-container {
    display: none;
  }
  .overview-container-mobile {
    display: initial;
  }
}
@media only screen and (min-width: 701px) {
  .overview-container {
    display: initial;
  }
  .overview-container-mobile {
    display: none;
    width: 0%;
  }
}
