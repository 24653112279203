.navbar-navbar {
  width: 100%;
  display: flex;
  z-index: 4;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
 
.navbar-navlink {
  display: contents;
}
 
.navbar-logo {
  width: 50px;
  align-self: flex-start;
  object-fit: cover;
}
 
.navbar-links-container {
  display: flex;
  flex-direction: column;
}
 
.navbar-link1 {
  color: var(--dl-color-feelers-dark);
  align-self: flex-end;
}
 
.navbar-link1:hover {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link1:active {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link2 {
  color: var(--dl-color-feelers-dark);
  align-self: flex-end;
}
 
.navbar-link2:hover {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link2:active {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link3 {
  color: var(--dl-color-feelers-dark);
  align-self: flex-end;
}
 
.navbar-link3:hover {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link3:active {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link4 {
  color: var(--dl-color-feelers-dark);
  align-self: flex-end;
  text-decoration: none;
}
 
.navbar-link4:hover {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 
.navbar-link4:active {
  color: var(--dl-color-feelers-accent);
  text-transform: uppercase;
}
 

 
@media(max-width: 767px) {
  .navbar-navbar {
    padding-right: var(--dl-space-space-doubleunit);
  }
}
 
@media(max-width: 479px) {
  .navbar-navbar {
    padding-right: var(--dl-space-space-doubleunit);
  }
}
